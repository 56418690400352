import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import ModalErro from "../components/Modal/ModalErro";
import ModalLoad from "../components/Modal/ModalLoad";
import ModalSucesso from "../components/Modal/ModalSucesso";
import NavbarOp from "../components/Navbar";
import axios from "axios";
import global from "../global";
import * as Sentry from "@sentry/react";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";

export default function ListaNFSe() {

    const [user, setUser] = useState(null);
    const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
    const [notasNfse, setNotasNfse] = useState([]);
    
    const [idDps, setIdDps] = useState('');
    const [dateDe, setdateDe] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [dataAte, setdataAte] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [pagina, setPagina] = React.useState(1);
    const [totalPaginas, setTotalPaginas] = React.useState(0);
    const [totalRegistros, setTotalRegistros] = React.useState(0);
    //padrão igual do erp
    const [totalPorPagina] = React.useState(15);

    //modal load
    const [showModalLoad, setShowModalLoad] = useState(false);

    //Modal Erro
    const [showModalErro, setShowModalErro] = useState(false);
    const [msgModalErro, setMsgModalErro] = useState("");

    //Modal Sucesso
    const [showModalSucesso, setShowModalSucesso] = useState(false);
    const [msgModalSucesso] = useState("");

    //functions
    const countNFSE = useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_CONTAR_NFSE, {

                idOrgranizacao: empresaSelecionada.IdOrganizacao,
                idFilial: empresaSelecionada.IdEmpresa,
                idDps: idDps != "" ? idDps : null,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte
    
            })
            .then((response) => {
    
                console.log(response);
    
                let data = response.data;

                console.log("data count nfe: ", data);
    
                if(data.code == 0){
    
                    //
                    setTotalRegistros(data.content);
    
                }else{
    
                    setMsgModalErro(data.msg);
                    setShowModalErro(true);
    
                }
                
            })
            .catch((error) => {

                Sentry.captureException(error);

                setMsgModalErro("Ocorreu um erro ao listar as NFSe. Tente novamente: " + error);
                setShowModalErro(true);
                
                console.log(error);
    
            })
            .finally(() => {
                
                setShowModalLoad(false);

            });

        }

    }, [dataAte, dateDe, empresaSelecionada, idDps, showModalErro]);

    const carregarNFSE = useCallback(() => {

        if(empresaSelecionada != null){
 
            setShowModalLoad(true);

            console.log("Data listar nfse", {
                
                idOrgranizacao: empresaSelecionada.IdOrganizacao,
                idFilial: empresaSelecionada.IdEmpresa,
                idDps: idDps != "" ? idDps : null,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte,
                paginaAtual: pagina,
                tamanhoPagina: totalPorPagina

            });

            console.log("URL: ", global.URL_BASE + global.PATH_LISTANFSE);
        
            axios.post(global.URL_BASE + global.PATH_LISTANFSE, {
                
                idOrgranizacao: empresaSelecionada.IdOrganizacao,
                idFilial: empresaSelecionada.IdEmpresa,
                idDps: idDps,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte,
                paginaAtual: pagina,
                tamanhoPagina: totalPorPagina

            })
            .then(function (response) {

                console.log(response);

                let data = response.data;

                if(data.code == 0){

                    console.log("DATA LISTA NFCe" , data)

                    //
                    setNotasNfse(JSON.parse(data.content) == null ? [] : JSON.parse(data.content));
                    countNFSE();

                }else{

                    setMsgModalErro(data.msg);
                    setShowModalErro(true);

                }

            })
            .catch(function (error) {

                Sentry.captureException(error);
                
                setMsgModalErro("Ocorreu um erro ao listar as NFSe. Tente novamente: " + error);
                setShowModalErro(true);

                console.log(error);

            })
            .finally(function () {
                setShowModalLoad(false);
            });

        }

    }, [dataAte, dateDe, empresaSelecionada, idDps, pagina, totalPorPagina]);

    useEffect(() => {
        console.log("PAGINA", pagina);
        carregarNFSE();
    }, [pagina]);

    useEffect(() => {
        setTotalPaginas(  Math.ceil(totalRegistros / totalPorPagina) );
    }, [totalRegistros, totalPorPagina]);

    return (
        <Container fluid style={{maxWidth: "1000px", paddingBottom: "1%", backgroundColor: "#FFF"}}>

            <ModalErro showModal={showModalErro} setShowModal={setShowModalErro} msg={msgModalErro} />
            <ModalLoad showModal={showModalLoad} setShowModal={setShowModalLoad} msg={msgModalSucesso} />
            <ModalSucesso showModal={showModalSucesso} setShowModal={setShowModalSucesso} msg={msgModalSucesso} />

            <NavbarOp user={user} setUser={setUser} empresaSelecionada={empresaSelecionada} setEmpresaSelecionada={setEmpresaSelecionada} />

            <Row style={{marginTop: "1%", textAlign: "center"}}>
                <h1>NFS-e</h1>
            </Row>

            <hr/>

            <Container style={{marginTop: "1%"}}>

                <Row>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="De: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dateDe} 
                                max={dataAte}
                                onChange={(e) => {

                                    setdateDe(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="Até: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dataAte} 
                                max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                                onChange={(e) => {

                                    setdataAte(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
            
                {/* <Row>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="Numero NFE: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="number"
                                value={numNFe} 
                                onChange={(e) => {

                                    setnumNFe(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    
                </Row> */}
                
            </Container>

            <Row>
                <Col>
                    {totalRegistros > 0 ? <h6>Total: {totalRegistros}</h6> : null}
                </Col>
            </Row>

            <hr />

            <Row 
            style={{marginBottom: "1%"}}
            >
                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{marginTop: "1%"}}
                >
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            carregarNFSE();

                        }}
                        >Buscar</Button>{' '}
                    </div>
                
                </Col>
                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{marginTop: "1%"}}
                >
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {
                            try {

                                setShowModalLoad(true);

                                axios.post(global.URL_BASE + global.PATH_BAIXARXMLNFSE, {
                    
                                    idOrgranizacao: empresaSelecionada.IdOrganizacao,
                                    idFilial: empresaSelecionada.IdEmpresa,
                                    idDps: idDps,
                                    dataEmissaoDe: dateDe,
                                    dataEmissaoAte: dataAte,
                                    paginaAtual: pagina,
                                    tamanhoPagina: totalPorPagina
                    
                                })
                                .then(function (response) {
                    
                                    console.log(response);
                    
                                    let data = response.data;
                    
                                    if(data.code == 0){
                    
                                        console.log("DATA LISTA NFCe" , data)
                    
                                        // Decodificar Base64
                                        const base64Content = data.content;
                                        const binaryString = atob(base64Content);
                                        const len = binaryString.length;
                                        const bytes = new Uint8Array(len);
                                        for (let i = 0; i < len; i++) {
                                            bytes[i] = binaryString.charCodeAt(i);
                                        }

                                        // Criar Blob a partir dos dados binários
                                        const blob = new Blob([bytes], { type: 'application/zip' });

                                        // Criar URL para o Blob
                                        const url = window.URL.createObjectURL(blob);

                                        // Criar elemento de link e acionar o download
                                        const nomeArquivo = 'NFSE_' + empresaSelecionada.NomeFantasia
                                        .normalize('NFD') // Decompor caracteres com acentos
                                        .replace(/[\u0300-\u036f]/g, '') // Remover os diacríticos (acentos)
                                        .replace(/\s+/g, '_') + "_" +
                                        dateDe.replaceAll("-", "_") + 
                                        (   dataAte != null 
                                            ? 
                                                "_ATE_" +
                                                dataAte.replaceAll("-", "_")
                                            :
                                            new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()
                                        ) +
                                        '.zip'
                                        ; // Substituir espaços por underscores
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', nomeArquivo ); // Nome do arquivo a ser baixado
                                        document.body.appendChild(link);
                                        link.click();

                                        // Remover o link do DOM
                                        document.body.removeChild(link);
                                        window.URL.revokeObjectURL(url);
                                        
                    
                                    }else{
                    
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                    
                                    }
                    
                                    setShowModalLoad(false);
                                    
                                })
                                .catch(function (error) {

                                    Sentry.captureException(error);

                                    setMsgModalErro("Ocorreu um erro ao listar as NFe. Tente novamente: " + error);
                                    setShowModalErro(true);
                                    
                                    setShowModalLoad(false);
                    
                                    console.log(error);
                    
                                });
                                
                            } catch (error) {

                                Sentry.captureException(error);
                                
                                setMsgModalErro(error.message);
                                setShowModalErro(true);
                                setShowModalLoad(false);

                            }
                        }}
                        >Baixar XML</Button>
                    </div>
                
                </Col>
            </Row>

            <hr />

            {
                //listar NFSE
                totalRegistros > 0 && 
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Serie</th>
                            <th>Data Emissão</th>
                            <th>Numero OS</th>
                            <th>Valor Total</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notasNfse.map((item) => {
                                return(
                                    <tr key={item.uuid}>
                                        <td>{item.numNota == null || item.idDps == undefined ? "-" : item.idDps}</td>
                                        <td>{item.serieNfseEmpresa == null || item.serieNfseEmpresa == undefined ? "-" : item.serieNfseEmpresa}</td>
                                        <td>{item.dataEmissao == null || item.dataEmissao == undefined ? "-" : ( (new Date(item.dataEmissao).getDate()) <= 9 ? ("0" + new Date(item.dataEmissao).getDate().toString()) : (new Date(item.dataEmissao).getDate().toString()) ).toString() + "/" + ( (new Date(item.dataEmissao).getMonth() + 1) <= 9 ? ("0" + (new Date(item.dataEmissao).getMonth() + 1).toString()) : new Date(item.dataEmissao).getMonth() + 1).toString()  + "/" + new Date(item.dataEmissao).getFullYear().toString()}</td>
                                        <td>{item.numeroOS == null || item.numeroOS == undefined ? "-" : item.numeroOS }</td>
                                        <td>{item.valorTotal == null || item.valorTotal == undefined ? "-" : item.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>{item.statusFinal == null || item.statusFinal == undefined ? "-" : item.statusFinal}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>    
            }
            {
                totalRegistros > 0 &&
                <div>
                    <hr />
                    <Row>
    
                        <Col>
    
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {
    
                                    console.log("Total Paginas: ", totalPaginas);
                                    console.log("Pagina: ", pagina);
    
                                    if(pagina > 1){
                                        setPagina(pagina - 1);
                                    }
    
                                }}
                                ><BsArrowLeftSquare size={30} /></Button>
                            </div>
    
                        </Col>
                        
                        <Col>
                            
                            <Form.Control 
                                style={{textAlign: "center", alignItems: "center"}}
                                type="text"
                                value={"Pagina " + pagina + " de " + totalPaginas }
                                onChange={(e) => {
    
                                    e.target.value = "Pagina " + pagina + " de " + totalPaginas;
    
                                }}
                            />
    
                        </Col>
                        
                        <Col>
    
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={() => {
    
                                    console.log("Total Paginas: ", totalPaginas);
                                    console.log("Pagina: ", pagina);
    
                                    if(pagina < totalPaginas){
                                        setPagina(pagina + 1);
                                    }
    
                                }}
                                ><BsArrowRightSquare size={30} /></Button>
                            </div>
                        
                        </Col>
    
                    </Row>
                </div>
            }

        </Container>
    );

}

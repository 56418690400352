import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form,
    FloatingLabel
} from 'react-bootstrap';
import * as Sentry from "@sentry/react";
import React, { useEffect } from 'react';
import axios from 'axios';
import global from "../../global";
import PropTypes from 'prop-types';
import ModalLoad from './ModalLoad';
import ModalErro from './ModalErro';
import { FaRegSquareCheck } from "react-icons/fa6";

ModalSelecionarEmpresa.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    user: PropTypes.object,
    empresaSelecionada: PropTypes.object

}

export default function ModalSelecionarEmpresa({showModal, setShowModal, user, empresaSelecionada}) {

    
    //modal load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //Modal Erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgModalErro, setMsgModalErro] = React.useState("");
    const [funcModalErro, setFuncModalErro] = React.useState(null);

    //empresas
    const [empresas, setEmpresas] = React.useState([]);
    
    useEffect(() => {
        
        if(user != null){

            console.log("userModalSelecionarEmpresa", user);

            setShowModalLoad(true);

            axios.post(global.URL_BASE + global.PATH_LISTAR_EMPRESAS, {
                                            
                idContador: user.IdContador.toString()
    
            })
            .then( (response) => {
    
                console.log(response);
    
                let data = response.data;
    
                if(data.code == 0){
    
                    //
                    console.log("Empresas: ", JSON.parse(data.content));
                    setEmpresas(JSON.parse(data.content));
    
                }else{
    
                    setMsgModalErro(data.msg);
                    setShowModalErro(true);
                    setFuncModalErro(() => () => { 
                        sessionStorage.removeItem("logado");
                        sessionStorage.removeItem("contadorObj");
                        sessionStorage.removeItem("empresaSelecionadaObj");
                        window.location.href = "/";
                        console.log("Teste");
                    });
    
                }
    
                setShowModalLoad(false);
                
            })
            .catch(function (error) {

                Sentry.captureException(error);
                
                setShowModalLoad(false);
    
                console.log(error);
    
            });
    
            setShowModalLoad(false);

        }


    }, [user]);

    return(

        <Container>
            <ModalLoad
                showModal={showModalLoad}
            />
            <ModalErro
                showModal={showModalErro}
                setShowModal={setShowModalErro}
                msg={msgModalErro}
                funcAoFechar={funcModalErro}
            />
            <Modal
            show={showModal}
            onHide={() => { 
                if(empresaSelecionada != null && empresaSelecionada != undefined){
                    setShowModal(false);
                } else {
                    setShowModal(true);
                } 
            }}
            keyboard={false}
            backdrop="static"
            id="modalSucess"
            centered
            size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Container>
                            <Row>
                                <Col>
                                    <h2>Selecione a empresa: </h2>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>

                        {
                                empresas == null || empresas.length == 0 
                            ? 
                                "Nenhuma empresa encontrada, por favor, contate o suporte!" 
                            : 
                                empresas.map((empresa) => {
                                
                                    return (
                                        
                                        <Row key={empresa.IdEmpresa}>
                                            <Row style={{marginBottom: "5%"}}>
                                                <Col 
                                                style={{textAlign: "center", alignItems: "center"}}
                                                
                                                >

                                                    <FloatingLabel
                                                        label="Nome empresa: "
                                                        className="mb-3"
                                                    >
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="" 
                                                            value={empresa.NomeFantasia} 
                                                            disabled
                                                        />
                                                    </FloatingLabel>

                                                </Col>
                                                <Col>
                                                    <div className="d-grid gap-2">
                                                        <Button
                                                        variant="success"
                                                        onClick={ () => {

                                                            sessionStorage.setItem("logado", true);
                                                            sessionStorage.setItem("contadorObj", JSON.stringify(user));
                                                            sessionStorage.setItem("empresaSelecionadaObj", JSON.stringify(empresa));
                                                            window.location.href = "/";
                                                            
                                                            setShowModal(false);

                                                        } }
                                                        ><FaRegSquareCheck  size={50}/></Button>{' '}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>

                                    );

                                })
                        }
                        <Row>
                        <Col style={{marginTop: "5%"}}>
                            <div className="d-grid gap-2">
                                <Button
                                variant={'danger'}
                                onClick={() => {
                                    sessionStorage.removeItem("logado");
                                    sessionStorage.removeItem("contadorObj");
                                    sessionStorage.removeItem("empresaSelecionadaObj");

                                    window.location.href = "/login";
                                }}
                                >Sair</Button>{' '}
                            </div>
                        </Col>
                        </Row>

                    </Container>
                </Modal.Body>
            </Modal>

        </Container>

        

    )

}
